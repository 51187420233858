<template>
    <div class="view contact">
        <DefaultHeader title="Contacter WildSys" />
        <div class="hero header" style="background-image:url('https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3870&q=80');"></div>
        <div class="block content">
            <VuiMessage color="success" v-if="message == 'success'">
                Votre message a bien été enregistré, vous allez reçevoir un e-mail de confirmation sous peu.<br />
                Nous vous recontacterons dans les plus brefs délais.
            </VuiMessage>
            <VuiMessage color="error" v-if="message == 'error'">
                Nous n'avons pas pu prendre en compte votre message.<br />
                Merci de nous contacter directement sur <a href="mailto:hello@wildsys.io?subject=Contact+sur+WildSys" target="_blank">hello@wildsys.io</a>
            </VuiMessage>
            <h3>Formulaire de contact</h3>
            <form class="form" @submit.prevent="sendContact">
                <div class="columns">
                    <div class="field">
                        <label for="name" class="required">Votre nom et prénom</label>
                        <VuiInput id="name" type="text" v-model="form.name" placeholder="John Doe" required data-1p-ignore />
                    </div>
                    <div class="field">
                        <label for="company" class="required">Votre entreprise</label>
                        <VuiInput id="company" type="text" v-model="form.company" placeholder="ACME Inc." required data-1p-ignore />
                    </div>
                    <div class="field">
                        <label for="email" class="required">Votre adresse e-mail</label>
                        <VuiInput id="email" type="text" v-model="form.email" placeholder="john.doe@acme.com" required data-1p-ignore />
                    </div>
                    <div class="field">
                        <label for="phone">Votre numéro de téléphone</label>
                        <VuiInput id="phone" type="phone" v-model="form.phone" placeholder="+33601020304" required data-1p-ignore />
                    </div>
                </div>
                <div>
                    <div class="field">
                        <label for="subject" class="required">Sujet</label>
                        <VuiSelector id="subject" v-model="form.subject" placeholder="En quoi pouvons-nous vous aider ?" :values="options" required />
                    </div>
                    <div class="field">
                        <label for="message" class="required">Votre message</label>
                        <VuiTextarea id="message" type="text" v-model="form.message" placeholder="Indiquez votre projet, vos objectifs, vos besoins" rows="12" data-1p-ignore />
                        <div class="ui text muted">
                            Un minimum de 200 caractères est requis.
                            <span v-if="form.message && form.message?.length < 200">({{ 200 - form.message.length }} encore nécessaire)</span>
                        </div>
                    </div>
                    <div class="field">
                        <label for="budget">Avez-vous une enveloppe budgetaire ?</label>
                        <VuiInput id="budget" type="text" v-model="form.budget" placeholder="Nous pouvons aussi vous donner un ordre de grandeur si besoin" data-1p-ignore />
                    </div>
                    <div class="field captcha">
                        <div class="columns">
                            <label for="captcha">
                                <VueClientRecaptcha @getCode="getCaptchaCode" />
                            </label>
                            <VuiInput id="captcha" v-model="captcha" />
                        </div>
                        <p v-if="captcha != captchaOriginal">Merci d'entrer le code ci-dessus pour nous assurer que vous n'êtes pas un bot.</p>
                    </div>
                    <div class="submit">
                        <!-- <input type="submit" value="Envoyer votre demande" /> -->
                        <VuiButton success :disabled="!isFormValid()">
                            Envoyer ma demande
                        </VuiButton>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <DefaultFooter />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import VueClientRecaptcha from 'vue-client-recaptcha'
import { sendEmail } from '@/services/brevo'

interface FormDef {
    name: string|null;
    company: string|null;
    email: string|null;
    phone: string|null;
    budget: string|null;
    subject: string|null;
    message: string|null;
}

const form = ref<FormDef>({
    name: null,
    company: null,
    email: null,
    phone: null,
    budget: null,
    subject: null,
    message: null
})

const message = ref<string|null>(null)
const captcha = ref(null)
const captchaOriginal = ref<string|null>(null)

const options = ref([
    {
        label: 'Question générale',
        value: 'Question générale'
    },
    {
        label: 'Partenariat',
        value: 'Partenariat'
    },
    {
        label: 'Support technique',
        value: 'Support technique'
    },
    {
        label: 'Proposition commerciale',
        value: 'Proposition commerciale'
    },
    {
        label: 'Demande de devis',
        value: 'Demande de devis'
    },
    {
        label: 'Réclamation',
        value: 'Réclamation'
    },
    {
        label: 'Suggestion ou feedback',
        value: 'Suggestion ou feedback'
    },
    {
        label: "Demande d'emploi ou de stage",
        value: "Demande d'emploi ou de stage"
    },
    {
        label: 'Médias et relations publiques',
        value: 'Médias et relations publiques'
    },
    {
        label: 'Autre',
        value: 'Autre'
    }
])

const sendContact = function(){
    const fm = form.value
    sendEmail({
        'templateId': 3,
        'to': [
            {
                'email': 'hello@wildsys.io',
                'name': 'WildSys'
            }
        ],
        'params': {
            'contact': {
                'company': fm.company,
                'name': fm.name,
                'email': fm.email,
                'phone': fm.phone,
                'budget': fm.budget,
                'subject': fm.subject,
                'message': fm.message?.replace(/\n/g, '<br />')
            }
        }
    }).then(() => {
        sendEmail({
            'templateId': 4,
            'to': [
                {
                    'email': fm.email,
                    'name': fm.name
                }
            ],
            'params': {
                'contact': {
                    'company': fm.company,
                    'name': fm.name,
                    'email': fm.email,
                    'phone': fm.phone,
                    'budget': fm.budget,
                    'subject': fm.subject,
                    'message': fm.message?.replace(/\n/g, '<br />')
                }
            }
        })
        form.value = {
            name: null,
            company: null,
            email: null,
            phone: null,
            budget: null,
            subject: null,
            message: null
        }
        message.value = 'success'
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, (error: any) => {
        message.value = 'error'
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        console.error(error)
    })
}

const getCaptchaCode = function(val: string){
    captchaOriginal.value = val
}

const isFormValid = function(){
    const fm = form.value
    if (!fm.name || !fm.email || !fm.company || !fm.subject || !fm.message || fm.message?.length < 200) return false
    if (!/^([\w\-+.]+)@([\w\-.]{4,})\.[a-z]{2,}$/.test(fm.email)) return false
    if (captcha.value != captchaOriginal.value) return false
    return true
}
</script>

<style lang="scss" scoped>
$mobile_size: 600px;

.view.contact
{
    min-height: 100vh;
    .hero.header
    {
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center center;
        height: 150px;
    }
    .block.content
    {
        max-width: 1200px;
        margin: 60px auto;
        @media (max-width: $mobile_size) {
            padding: 0px 30px;
        }
        .form
        {
            .field
            {
                margin: 10px 0px;
                position: relative;
                label
                {
                    font-weight: 500;
                    &.required::after
                    {
                        content: '*';
                        margin-left: 5px;
                        z-index: 100;
                        color: #cf3636;
                    }
                }
                input,
                select,
                textarea
                {
                    margin-top: 5px;
                    background-color: #ffffff;
                    border: 1px solid #8f959e;
                    border-radius: 4px;
                    padding: 8px 15px;
                    outline: none;
                    width: 100%;
                    position: relative;
                    &:focus
                    {
                        border: 2px solid #02B97D;
                        padding: 7px 14px;
                    }
                }
                textarea
                {
                    padding: 12px 15px;
                    &:focus
                    {
                        padding: 11px 14px;
                    }
                }
                &.captcha
                {
                    .columns
                    {
                        display: inline-grid;
                        grid-auto-flow: column;
                        align-items: center;
                        justify-content: center;
                        margin: 0px;
                        label
                        {
                            display: block;
                            height: 50px;
                        }
                        input
                        {
                            display: inline-block;
                            width: 120px;
                            margin: 0px;
                        }
                    }
                    p
                    {
                        margin: 5px 0px 0px 0px;
                    }
                }
            }
            .columns
            {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                column-gap: 30px;
                .field
                {
                    width: calc(50% - 15px);
                    @media (max-width: $mobile_size) {
                        width: 100%;
                    }
                }
            }
            .submit
            {
                input
                {
                    border-radius: 4px;
                    background-color: #02B97D;
                    color: #ffffff;
                    font-weight: 500;
                }
            }
        }
    }
}
</style>