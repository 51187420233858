<template>
    <div class="cmp header not mobile" :class="{'reduced': headerReduced}" ref="header">
        <div class="wrapper not mobile">
            <div class="links">
                <router-link to="/" class="home" @click.prevent="smoothScroll('#home')">
                    <div class="logo">
                        <LogoSVG fill="#ffffff" />
                    </div>
                    <div class="text">WildSys</div>
                </router-link>
                <div v-if="props.title" class="title">
                    {{ props.title }}
                </div>
                <template v-if="props.links">
                    <template v-for="link in props.links" :key="link">
                        <router-link :to="link.to" v-if="link.to" class="link">
                            {{ link.label }}
                        </router-link>
                        <a v-else-if="link.anchor" :href="`#${link.anchor}`" class="link">
                            {{ link.label }}
                        </a>
                    </template>
                </template>
            </div>
            <div class="actions">
                <router-link to="/contact" class="button">
                    Contact
                </router-link>
            </div>
        </div>
        <div class="wrapper mobile only">
            <div class="logo">
                <router-link to="/" class="home" @click.prevent="smoothScroll('#home')">
                    <LogoSVG fill="#ffffff" />
                </router-link>
            </div>
            <div class="title">
                {{ title }}
            </div>
            <div class="pwa toggle" @click="mobileMenu = !mobileMenu">
                <font-awesome-icon :icon="['fas', 'bars']" />
            </div>
        </div>
    </div>
    <div class="pwa menu" :class="{'active': mobileMenu}">
        <div class="close" @click="mobileMenu = false">
            <font-awesome-icon :icon="['fal', 'circle-xmark']" />
        </div>
        <div class="logo">
            <LogoSVG fill="#ffffff" />
        </div>
        <router-link to="/" class="link">
            Nos services
        </router-link>
        <template v-if="props.links">
            <hr />
            <template v-for="link in props.links" :key="link">
                <router-link :to="link.to" v-if="link.to" class="link">
                    {{ link.label }}
                </router-link>
                <a v-else-if="link.anchor" :href="`#${link.anchor}`" class="link">
                    {{ link.label }}
                </a>
            </template>
        </template>
        <hr />
        <router-link to="/contact" class="link">
            Contact
        </router-link>
        <router-link to="/company/jobs" class="link">
            Recrutement
        </router-link>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import LogoSVG from '@/components/design/LogoSVG.vue'
import { ref, defineProps, onBeforeUnmount, onMounted } from 'vue'
import { RouterLinkProps } from 'vue-router'

interface LinkDefinition {
    to: string|RouterLinkProps;
    label: string;
}

const header = ref<HTMLElement|null>(null)
const headerReduced = ref(false)
const mobileMenu = ref(false)

const props = defineProps({
    title: {
        type: String
    },
    links: {
        type: Array as PropType<LinkDefinition[]>
    }
})

const manageScroll = function(evt: MouseEvent){
    const scrollY = window.scrollY
    headerReduced.value = (scrollY > (header.value as HTMLElement)?.offsetHeight)
}

const attachEvents = function(){
    window.addEventListener('scroll', manageScroll)
}

const dettachEvents = function(){
    window.removeEventListener('scroll', manageScroll)
}

const smoothScroll = function(anchor){
    window.scrollTo({
        top: document.body.querySelector(anchor)?.offsetTop,
        behavior: 'smooth'
    })
}

onMounted(() => attachEvents())
onBeforeUnmount(() => dettachEvents())
</script>

<style lang="scss" scoped>
$reduced_size: 22px;
$mobile_size: 600px;

.cmp.header
{
    position: sticky;
    top: 0px;
    left: 0px;
    right: 0px;
    transition: all 0.4s 0s;
    z-index: 10;
    background-color: #3b3b3b;
    backdrop-filter: blur(5px);
    .wrapper
    {
        &.not.mobile
        {
            @media (max-width: $mobile_size) {
                display: none;
            }
            display: grid;
            grid-template-columns: repeat(2, auto);
            justify-content: space-between;
            max-width: 1200px;
            margin: auto;
            transition: inherit;
            .links
            {
                display: grid;
                grid-auto-flow: column;
                align-items: center;
                justify-content: flex-start;
                column-gap: 30px;
                transition: inherit;
                .home
                {
                    padding: 8px 16px;
                    color: #ffffff;
                    margin-right: 20px;
                    height: 80px;
                    width: 120px;
                    text-align: center;
                    transition: inherit;
                    height: ($reduced_size * 2);
                    padding-top: ($reduced_size / 2);
                    .logo
                    {
                        width: $reduced_size;
                        height: $reduced_size;
                        transform: translateX(-$reduced_size) translateY(-1px);
                        margin: auto;
                        transition: inherit;
                        svg
                        {
                            transition: inherit;
                            width: $reduced_size;
                            height: $reduced_size;
                        }
                    }
                    .text
                    {
                        transition-property: transform;
                        transition-duration: inherit;
                        transition-delay: inherit;
                        font-weight: 500;
                        transform: translateX($reduced_size + 6px) translateY(-$reduced_size);
                    }
                }
                .link
                {
                    font-size: 0.9em;
                    color: #ffffff;
                }
                .title
                {
                    color: #ffffff;
                    font-weight: 500;
                }
            }
            .actions
            {
                display: grid;
                align-items: center;
                transition: inherit;
                .button
                {
                    transition: inherit;
                    background-color: #242424;
                    color: #ffffff;
                    border-radius: 100px;
                    padding: 8px 16px;
                    font-weight: 500;
                    font-size: 0.9em;
                    padding: 4px 16px;
                }
            }
        }
        &.mobile.only
        {
            display: none;
            @media (max-width: $mobile_size) {
                display: block;
            }
            position: relative;
            .logo
            {
                text-align: center;
                padding: 8px 0px 2px 0px;
                .home
                {
                    display: block;
                    margin: 0px auto;
                    width: 32px;
                    height: 32px;
                    margin: 0px auto;
                    transform: translateX(2px) translateY(3px);
                    svg
                    {
                        height: 32px;
                        width: 32px;
                    }
                }
            }
            .title
            {
                font-weight: 500;
                color: #f0f0f0;
                text-align: center;
                padding: 4px 8px 8px 8px;
            }
            .pwa.toggle
            {
                width: 64px;
                font-size: 18px;
                color: #ffffff;
                display: none;
                justify-content: center;
                align-items: center;
                transition: all 0.4s 0s;
                position: absolute;
                right: 0.25em;
                top: 1.65em;
                margin-top: -1.1em;
                padding: 8px;
                color: #f0f0f0;
                @media (max-width: $mobile_size) {
                    &
                    {
                        display: grid;
                    }
                }
            }
        }
    }
}
.pwa.menu
{
    position: fixed;
    left: 0px;
    top: -100%;
    background-color: #3b3b3b;
    color: #ffffff;
    width: 100%;
    padding: 8px 0px;
    opacity: 0;
    transition: all 0.4s 0s;
    z-index: 10;
    box-shadow: rgba(#5e7382, 45%) 0px 0px 12px;
    hr
    {
        margin: 12px 40px 8px 40px;
        border: none;
        border-bottom: 1px solid #4f4f4f;
    }
    .close
    {
        position: absolute;
        height: 24px;
        height: 24px;
        display: grid;
        align-items: center;
        justify-content: center;
        right: 10px;
        top: 10px;
        color: rgba(#ffffff, 40%);
        font-size: 22px;
    }
    .logo
    {
        text-align: center;
        svg
        {
            height: 42px;
        }
    }
    .link
    {
        display: block;
        color: #ffffff;
        padding: 8px 16px;
    }
    &.active
    {
        top: 0px;
        opacity: 1;
    }
}
</style>