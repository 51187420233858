import { ref, watch, computed, inject } from 'vue'
import { useCookies as uc } from 'vue3-cookies'
import store from '@/store'

const allowCookies = ref<boolean>()

export default function useCookies(gtag: any) {

    const { cookies } = uc()

    if (cookies.isKey('cookies_consent')) {
        allowCookies.value = cookies.get('cookies_consent') === 'true'
        gtag.optIn()
    } else {
        allowCookies.value = undefined
    }

    watch(allowCookies, () => {
        if (allowCookies.value != undefined) {
            cookies.set('cookies_consent', allowCookies.value.toString())
            if (allowCookies.value) {
                gtag.optIn()
            } else {
                gtag.optOut()
            }
        }
    })

    const showBanner = computed(() => {
        return allowCookies.value === undefined && store.state.nocookies == false
    })

    const okClicked = () => (allowCookies.value = true)

    return { allowCookies, showBanner, okClicked }
}
