import axios, { AxiosInstance, isAxiosError } from 'axios'

const brevoClient = axios.create({
    baseURL: 'https://api.brevo.com',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'api-key': process.env.VUE_APP_BREVO_TOKEN
    }
})

export const sendEmail = async function(data: any){
    try {
        const response = await brevoClient.post(`/v3/smtp/email`, data)
        return response.status == 200
    }
    catch (e) {
        console.error(e)
    }
}