<template>
    <div class="ui business footer">
        <div class="columns">
            <div>
                <strong>WildSys</strong><br>
                9/36 rue Chanzy<br>
                59350 Saint-André-Lez-Lille<br><br>
                <a href="mailto:hello@wildsys.io">hello@wildsys.io</a>
            </div>
            <div>
                <div class="link">
                    <font-awesome-icon :icon="['fad', 'caret-right']" />
                    <router-link to="/legals">Mentions légales</router-link>
                </div>
            </div>
            <div>
                <div class="social">
                    <div>
                        <a href="https://www.linkedin.com/company/wildsys/" target="_blank" rel="external">
                            <font-awesome-icon :icon="['fab', 'linkedin']" />
                        </a>
                        <a href="https://github.com/wildsys" target="_blank" rel="external">
                            <font-awesome-icon :icon="['fab', 'square-github']" />
                        </a>
                    </div>
                </div>
                &copy; Copyright WildSys 2023
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.ui.business.footer
{
    background-color: $vui-dark;
    color: $vui-muted-lighter;
    padding: 60px;
    a
    {
        color: $vui-muted-lighter;
        &:hover
        {
            color: $vui-white;
        }
    }
    .columns
    {
        max-width: 1024px;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px;
        &>div
        {
            &:last-of-type
            {
                text-align: center;
            }
        }
        .social
        {
            text-align: center;
            margin: 0px 0px 30px 0px;
            div
            {
                display: inline-grid;
                grid-auto-flow: column;
                grid-gap: 15px;
                font-size: 1.5em;
            }
        }
        @include for-size("xs") {
            grid-template-columns: 1fr;
            grid-auto-flow: row;
        }
    }
    .link
    {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 16px auto;
        align-items: center;
        grid-gap: 5px;
    }
}
</style>