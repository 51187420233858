import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueKinesis from 'vue-kinesis'

import VueGtag from 'vue-gtag'
import VueCookies from 'vue3-cookies'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faDocker, faFigma, faGithub, faGithubSquare, faLinkedin, faPython, faSass, faSlack, faSquareJs, faStripe, faVuejs } from '@fortawesome/free-brands-svg-icons'
import { faArrowRightArrowLeft, faBars, faClouds, faComputerMouseScrollwheel, faDatabase, faDisplayChartUpCircleCurrency, faHeadset, faMessageSms, faWebhook } from '@fortawesome/pro-solid-svg-icons'
import { faBookBookmark, faBullseyePointer, faCaretRight, faPeopleArrows, faQuestionSquare, faShuttleSpace, faTelescope, faTrafficLightSlow, faTricycle } from '@fortawesome/pro-duotone-svg-icons'
import { faBook, faBrainCircuit, faCircleXmark, faPencil, faPersonChalkboard, faSignature, faThoughtBubble } from '@fortawesome/pro-light-svg-icons'
library.add(
    faLinkedin,
    faGithubSquare,
    faStripe,
    faSlack,
    faMessageSms,
    faArrowRightArrowLeft,
    faDisplayChartUpCircleCurrency,
    faHeadset,
    faCaretRight,
    faVuejs,
    faFigma,
    faSquareJs,
    faPython,
    faDatabase,
    faWebhook,
    faBrainCircuit,
    faThoughtBubble,
    faPencil,
    faSignature,
    faBook,
    faGithub,
    faPersonChalkboard,
    faComputerMouseScrollwheel,
    faPeopleArrows,
    faBullseyePointer,
    faTrafficLightSlow,
    faBookBookmark,
    faTelescope,
    faDocker,
    faClouds,
    faSass,
    faTricycle,
    faShuttleSpace,
    faQuestionSquare,
    faBars,
    faCircleXmark
)

import '@wildsys/visionui-vue3/style.css'
import VisionUI from '@wildsys/visionui-vue3'

import HeroHeader from '@/components/design/HeroHeader.vue'
import BusinessFooter from '@/components/design/BusinessFooter.vue'
import DefaultHeader from '@/components/generic/DefaultHeader.vue'
import DefaultFooter from '@/components/generic/DefaultFooter.vue'
import SiteMenu from '@/components/design/SiteMenu.vue'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueKinesis)
app.use(VisionUI)
app.use(VueCookies, {
    expireTimes: '30d',
    path: '/',
    domain: '',
    secure: true,
    sameSite: 'None'
})
app.use(VueGtag, {
    config: {
        id: 'G-13Q6W7LHPZ'
    },
    enabled: false
    // {
    //     config: { id: 'G-13Q6W7LHPZ' },
    //     router,
    //     enabled: false,
    // }
})
app.provide('gtag', app.config.globalProperties.$gtag)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('HeroHeader', HeroHeader)
app.component('DefaultHeader', DefaultHeader)
app.component('DefaultFooter', DefaultFooter)
app.component('SiteMenu', SiteMenu)
app.component('BusinessFooter', BusinessFooter)

app.mount('#app')
