<template>
    <div class="ui hero header" :class="{'fullheight': fullheight}">
        <template v-if="props.author">
            <a v-if="props.authorLink" :href="props.authorLink" target="_blank" class="author">
                &copy; {{ props.author }}
            </a>
            <div v-else class="author">
                &copy; {{ props.author }}
            </div>
        </template>
        <img :src="props.src" :class="props.portrait ? 'portrait' : 'landscape'" :style="props.zoom ? `transform: scale(${props.zoom});` : null" alt="Une image contextualisée à la page" />
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'

const props = defineProps({
    src: {
        type: String
    },
    author: {
        type: String
    },
    authorLink: {
        type: String
    },
    portrait: {
        type: Boolean,
        default: false
    },
    zoom: {
        type: Number
    },
    fullheight: {
        type: Boolean
    }
})
</script>

<style lang="scss" scoped>
.ui.hero.header
{
    position: relative;
    overflow: hidden;
    &.fullheight
    {
        height: 100%;
        min-height: 30vh;
    }
    &>img
    {
        position: absolute;
        top: -9999px;
        bottom: -9999px;
        left: -9999px;
        right: -9999px;
        margin: auto;
        &.landscape
        {
            max-height: 100vh;
            @include for-size('lg') {
                max-height: 110vh;
            }
            @include for-size('xl') {
                max-height: 120vh;
            }
        }
        &.portrait
        {
            min-width: 100vw;
            @include for-size('lg') {
                min-width: 110vw;
            }
            @include for-size('xl') {
                min-width: 120vw;
            }
        }
    }
    .author
    {
        opacity: 0.3;
        position: absolute;
        bottom: 15px;
        right: 15px;
        background-color: #000000;
        color: $vui-white;
        padding: 0.25em 0.8em;
        font-weight: 500;
        font-size: 0.8em;
        border-radius: $primary-radius;
        text-decoration: none;
        cursor: pointer;
        z-index: 15;
        &:hover
        {
            opacity: 1;
        }
    }
}
</style>