<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="200" height="200" viewBox="0 0 200 200" xml:space="preserve">
        <g transform="matrix(1.3873333013 0 0 1.3873333013 49.6407907617 100.0011416918)" id="YpRJ1mnN7_2R9A7TBRDBf"  >
            <path :fill="props.fill" style="stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  transform=" translate(-0.0000064284, -0.0000071944)" d="M -24.76258 -44.7569 C -25.91996 -44.7569 -27.00148 -44.18106 -27.64759 -43.22081 C -28.2937 -42.26057 -28.419700000000002 -41.04179 -27.983710000000002 -39.96967 L 5.325040000000001 41.937099999999994 L 5.325040000000001 41.937099999999994 C 5.959060000000001 43.496179999999995 7.406180000000001 44.5744 9.081470000000001 44.73595999999999 C 10.756760000000002 44.89751999999999 12.38325 44.11571999999999 13.303530000000002 42.70653999999999 L 27.525370000000002 20.92934999999999 L 27.525370000000002 20.92934999999999 C 28.312260000000002 19.724429999999987 28.458740000000002 18.21038999999999 27.917450000000002 16.87696999999999 L 4.879940000000001 -39.87398000000002 L 4.879940000000001 -39.87398000000002 C 3.681650000000001 -42.825870000000016 0.8137100000000013 -44.75689000000002 -2.372119999999999 -44.75689000000002 z" stroke-linecap="round" />
        </g>
        <g transform="matrix(1.3873333013 0 0 1.3873333013 106.674062778 100.0011416918)" id="1me5_kJzWAct2cdkMXd8g"  >
            <path :fill="props.fill" style="stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  transform=" translate(-0.0000105192, -0.0000071944)" d="M -24.72935 -44.7569 C -25.88673 -44.7569 -26.96825 -44.18106 -27.61436 -43.22081 C -28.26047 -42.26057 -28.386470000000003 -41.04179 -27.950480000000002 -39.96967 L 5.358270000000001 41.937099999999994 L 5.358270000000001 41.937099999999994 C 5.992290000000001 43.496179999999995 7.4394100000000005 44.5744 9.114700000000001 44.73595999999999 C 10.789990000000001 44.89751999999999 12.41648 44.11571999999999 13.336760000000002 42.70653999999999 L 27.40448 21.16534999999999 L 27.40448 21.16534999999999 C 28.28791 19.81259999999999 28.45237 18.11281999999999 27.84467 16.61579999999999 L 4.913170000000001 -39.87398000000002 L 4.913170000000001 -39.87398000000002 C 3.714880000000001 -42.825870000000016 0.8469400000000009 -44.75689000000002 -2.3388899999999992 -44.75689000000002 z" stroke-linecap="round" />
        </g>
        <g transform="matrix(-1.3873333013 0 0 1.3873333013 162.1812681629 72.0186290046)" id="aIDwoGpKX1FEBtJhzxOVL"  >
            <path :fill="props.fill" style="stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  transform=" translate(-0.0000026611, 0.0000060298)" d="M -16.59935 -24.58855 C -17.71273 -24.58855 -18.741690000000002 -23.995070000000002 -19.299200000000003 -23.03133 C -19.856710000000003 -22.06759 -19.858240000000002 -20.87975 -19.303200000000004 -19.91458 L 5.5896199999999965 23.371769999999998 L 5.5896199999999965 23.371769999999998 C 6.056989999999996 24.184479999999997 6.950749999999997 24.655209999999997 7.885309999999997 24.580879999999997 C 8.819869999999996 24.506549999999997 9.627979999999997 23.900449999999996 9.960999999999997 23.024079999999998 L 19.383029999999998 -1.7707500000000032 L 19.383029999999998 -1.7707500000000032 C 19.866 -3.0417100000000032 19.825989999999997 -4.452210000000003 19.271769999999997 -5.693760000000003 L 12.930429999999998 -19.899400000000004 L 12.930429999999998 -19.899400000000004 C 11.657169999999997 -22.751700000000003 8.825649999999998 -24.588560000000005 5.702059999999998 -24.588560000000005 z" stroke-linecap="round" />
        </g>
    </svg>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'

const props = defineProps({
    fill: {
        type: String,
        default: 'rgb(2,185,125)'
    }
})
</script>
