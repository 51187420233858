import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import ContactView from '@/views/ContactView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'home',
    component: HomeView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/company',
    children: [
      {
        path: 'legals',
        name: 'companyLegals',
        component: () => import(/* webpackChunkName: "companyLegals" */ /* webpackPrefetch: true */ '@/views/LegalsView.vue')
      },
      {
        path: 'jobs',
        name: 'companyJobs',
        component: () => import(/* webpackChunkName: "companyJobs" */ /* webpackPrefetch: true */ '@/views/JobsView.vue')
      }
    ]
  },
  {
    path: '/cookies',
    component: () => import(/* webpackChunkName: "cookiesPolicy" */ /* webpackPrefetch: true */ '@/views/CookiesPolicy.vue')
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "companyJobs" */ /* webpackPrefetch: true */ '@/views/NotFoundView.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(){
    window.scrollTo({
      top: 0
    })
  }
})

export default router
