import { createStore } from 'vuex'

export default createStore({
  state: {
    nocookies: false
  },
  getters: {
  },
  mutations: {
    nocookies(state:any){
        state.nocookies = true
    }
  },
  actions: {
  },
  modules: {
  }
})
