<template>
    <HeroHeader
      :zoom="1.2"
      src="https://images.unsplash.com/photo-1611716524065-622312678d68?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3811&q=80">
        <div class="ui site menu">
            <div class="logo">
                <router-link to="/" class="image"></router-link>
            </div>
            <div class="title">{{ props.title }}</div>
        </div>
    </HeroHeader>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'

const props = defineProps({
    title: {
        type: String,
        required: true
    }
})
</script>

<style lang="scss" scoped>
.ui.site.menu
{
    position: relative;
    z-index: 1;
    display: grid;
    width: 100%;
    min-height: 100%;
    background-color: rgba($vui-dark, 0.7);
    grid-template-columns: 32px auto 1fr;
    align-items: center;
    padding: 15px 60px;
    color: $vui-white;
    grid-gap: 20px;
    .logo
    {
        height: 100%;
        width: 32px;
        .image
        {
            background-image: url('@/assets/img/logo-white.svg');
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-position: center center;
            display: block;
            height: 100%;
            width: 32px;
        }
    }
    .title
    {
        font-weight: bold;
    }
}
</style>
