<template>
    <div class="view home">
        <div id="debug"></div>
        <a id="home"></a>
        <HomeHeader />
        <div class="intro">
            <div class="text">
                <div>
                    <h1 class="title">Explore. Digitalize. Succeed.</h1>
                    <p class="subtitle">Façonnez votre avenir numérique avec notre savoir-faire en développement et hébergement de solutions digitales, taillées sur mesure pour votre succès.</p>
                </div>
            </div>
        </div>
        <a id="ctoaas"></a>
        <div class="content">
            <div class="block title animation ctoaas" @mousemove.prevent="moveCard" @mouseout.prevent="outCard">
                <div class="blur">
                    <img :src="`https://images.unsplash.com/photo-1572177812156-58036aae439c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=${imgSize}&q=30`" alt="Le mot Projects en 3D sur un mur en béton" />
                </div>
                <div class="container">
                    <div class="wrapper">
                        <h2 class="title">CTO as a Service</h2>
                        <div class="subtitle">Maximisez votre potentiel technologique avec notre service CTO as a Service. L'expertise d'un CTO sans les contraintes.</div>
                    </div>
                </div>
            </div>
            <div class="block text">
                <p>Nous vous proposons notre compétence de Chief Technology Officer (CTO) expérimenté pour bénéficier de notre expertise stratégique et technique, sans la contrainte d'une embauche à temps plein. Cela permet d'accéder à une direction technologique de haut niveau de manière flexible et rentable, particulièrement utile pendant la phase de démarrage de votre projet.</p>
            </div>
            <div class="block text">
                <div class="columns">
                    <div class="column">
                        <div class="title">Gestion de projet</div>
                        <p>Nous pouvons vous aider à organiser et superviser la gestion de projets technologiques, du développement à la mise en œuvre. Nous vous aidons à cadrer votre projet de transformation digital selon vos exigences métiers et budgétaires, à coordonner les équipes internes et externes, à assurer le respect des délais et à gérer les risques.</p>
                    </div>
                    <div class="column">
                        <div class="title">Architecture technique</div>
                        <p>Nous pouvons travaille ensemble à l'élaboration d'une architecture technique solide et scalable pour les solutions logicielles ou les infrastructures de l'entreprise. Nous veillons à ce que votre environnement technique réponde aux besoins présents et futurs de votre entreprise, de vos clients et de vos collaborateurs.</p>
                    </div>
                    <div class="column">
                        <div class="title">Recrutement de talents</div>
                        <p>Vous souhaitez engager un Développeur ou un Data Analyst/Scientist ? Nous pouvons vous apporter notre expertise dans le recrutement et la gestion des talents technologiques. Nous vous aidons à identifier les compétences requises, participons aux processus d'embauche et proposons des stratégies de développement des compétences pour vos équipes techniques.</p>
                    </div>
                </div>
            </div>
        </div>
        <a id="develop"></a>
        <div class="content">
            <div class="block title animation develop" @mousemove.prevent="moveCard" @mouseout.prevent="outCard">
                <div class="blur">
                    <img :src="`https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=${imgSize}&q=30`" alt="Un ordinateur avec du code JavaScript" />
                </div>
                <div class="container">
                    <div class="wrapper">
                        <h2 class="title">Développement</h2>
                        <div class="subtitle">Transformez votre idée en une application épique ! Notre équipe crée des apps sur mesure et prêtes à conquérir le monde numérique.</div>
                    </div>
                </div>
            </div>
            <div class="block text">
                <div class="columns">
                    <div class="column">
                        <div class="title">Offrez une expérience exclusive à vos utilisateurs</div>
                        <p>Imaginez une application avec une interface utilisateur élégante et intuitive, des fonctionnalités avancées et une performance inégalée&nbsp;: nous vous aiderons à la concrétiser.</p>
                        <p>Grâce à notre expertise, nous vous aiderons à amener vos utilisateurs à se sentir privilégiés et à rester fidèles à votre marque.</p>
                    </div>
                    <div class="column">
                        <div class="title">La performance au service de vos utilisateurs</div>
                        <p>Rien de plus frustrant qu'une interface qui ne semble ne pas charger les données, qui trainent à traiter une demande ou l'absence d'information sur les erreurs communes.</p>
                        <p>Dans notre approche User First, nous vous apportons une vision qui se préocupe d'abord de vos utilisateurs finaux, car ce sont eux qui permettent à votre activité de croître.</p>
                    </div>
                    <div class="column">
                        <div class="title">Protégez la confidentialité de vos utilisateurs</div>
                        <p>Au-delà d'une simple mise en conformité, la sécurisation des données utilisateurs est avant tout une image forte de confiance dans votre marque.</p>
                        <p>En nous confiant votre besoin, nous pouvons mettre en place des niveaux de sécurité et de protection qui rassurent vos clients.</p>
                    </div>
                    <div class="column">
                        <div class="title">Anticipez la croissance de votre application</div>
                        <p>Parce que développer une application métier est un engagement financier fort, nous designons nos solutions pour qu'elles puissent croître avec votre activité.</p>
                        <p>Nos solutions technologiques sont flexibles et évolutives et peuvent donc s'adapter aux besoins changeants de votre entreprise et aux attentes de vos utilisateurs sans devoir à chaque fois repartir de zéro.</p>
                    </div>
                    <div class="column">
                        <div class="title">Ne restez plus seuls après le lancement</div>
                        <p>Bénéficiez d'un soutien continu et d'une maintenance de qualité&nbsp;: nous sommes là pour vous accompagner, même après le lancement de votre application.</p>
                        <p>Notre équipe reste à votre disposition pour fournir un support technique et assurer une maintenance continue, afin que votre application fonctionne de manière optimale.<sup class="footnote"><a href="#footnote-1">1</a></sup></p>
                    </div>
                </div>
            </div>
            <div class="block text">
                <div class="scroll">
                    <div>
                        Prêt à nous faire confiance ? Déroulez la page pour voir le cheminement d'un contrat avec notre équipe. 😃
                    </div>
                    <div class="icon">
                        <div class="mouse">
                            <div class="wheel"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block text parallax">
                <div class="galaxy">
                    <div class="step">
                        <div class="content">
                            <div class="overlay" style="background-image:url('https://images.unsplash.com/photo-1569229761589-8838faca0b91?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3805&q=30');"></div>
                            <div class="id">1</div>
                            <h3 class="title">Rédaction du Cahier des Charges</h3>
                            <div class="technology">
                                <div class="title">Prérequis</div>
                                <div class="list">
                                    <div class="item">
                                        <font-awesome-icon :icon="['fad', 'bullseye-pointer']" /> Vos Objectifs
                                    </div>
                                    <div class="item">
                                        <font-awesome-icon :icon="['fad', 'traffic-light-slow']" /> Vos Contraintes
                                    </div>
                                    <div class="item">
                                        <font-awesome-icon :icon="['fad', 'people-arrows']" /> Un(e) coordinateur(rice) de projet
                                    </div>
                                </div>
                            </div>
                            <div class="description">
                                <p>Vous êtes prêt à nous confier votre projet ? Parfait ! Mais avant de sortir les premières lignes de code, il faut que nous nous comprenions.</p>
                                <p>Cette compréhension passe par un Cahier des Charges. C'est l'élément essentiel dont vous avez besoin, et nous aussi.</p>
                                <p>En mettant par écrit vos idées, vos besoins et vos attentes, vous assurez une compréhension mutuelle sans risquer de se perdre en cours de route. C'est le secret pour éviter les malentendus et les déceptions, et pour garantir un résultat final le plus idéal possible. De plus, il nous permettra de vous livrer une estimation du coût de votre future application la plus précise possible.</p>
                                <p>Alors, êtes-vous prêt à donner vie à votre projet avec un cahier des charges qui fera sensation&nbsp;?</p>
                                <p class="tips"><strong>💡 Astuce !</strong> Même si vous pensez détenir un Cahier des Charges, c'est le plus souvent une Expression de Besoins qui nécessite d'être challengée pour être en phase avec vos contraintes. C'est pourquoi nous pouvons vous aider à le rédiger.</p>
                            </div>
                        </div>
                    </div>
                    <div class="step">
                        <div class="content">
                            <div class="overlay" :style="`background-image:url('https://images.unsplash.com/photo-1501139083538-0139583c060f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=${imgSize}&q=30');`"></div>
                            <div class="id">2</div>
                            <h3 class="title">Planning</h3>
                            <div class="technology">
                                <div class="title">Prérequis</div>
                                <div class="list">
                                    <div class="item">
                                        <font-awesome-icon :icon="['fad', 'book-bookmark']" /> Le Cahier des Charges
                                    </div>
                                    <div class="item">
                                        <font-awesome-icon :icon="['fad', 'telescope']" /> Une Stratégie
                                    </div>
                                </div>
                            </div>
                            <div class="description">
                                <p>Nous avons travaillé ensemble à la rédaction du Cahier des Charges ? Bien ! Maintenant, pour vous rassurer et vous donner de la vision, nous allons mettre en place un Planning.<sup class="footnote"><a href="#footnote-2">2</a></sup></p>
                                <p>Une planification rigoureuse permet de suivre le déroulement du projet, de respecter les échéances et de prendre des mesures correctives si nécessaire.</p>
                                <p>Mais ça ne veut pas dire que nous ne pouvons pas montrer un peu de souplesse ou que nous allons nous enfermer dans notre cave pendant un&nbsp;an, le temps de développer votre application. Nous ponctuons les temps de développement avec des temps de synchronisation avec votre coordinateur(rice) et, selon la complexité du projet, avec des livraisons sur un environnement de test qui vous est dédié.<sup class="footnote"><a href="#footnote-3">3</a></sup></p>
                                <p class="tips"><strong>💡 Astuce !</strong> Pour votre image de marque, ne communiquez jamais de dates de sortie à vos clients et prospects avant d'être sûr de pouvoir fournir le service.</p>
                            </div>
                        </div>
                    </div>
                    <div class="step">
                        <div class="content">
                            <div class="overlay" style="background-image:url('https://images.unsplash.com/photo-1576153192396-180ecef2a715?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3774&q=30');"></div>
                            <div class="id">3</div>
                            <h3 class="title">Maquettes et Expérience Utilisateur (UX)</h3>
                            <div class="technology">
                                <div class="title">Technologies</div>
                                <div class="list">
                                    <div class="item">
                                        <font-awesome-icon :icon="['fal', 'thought-bubble']" /> De l'imagination
                                    </div>
                                    <div class="item">
                                        <font-awesome-icon :icon="['fab', 'figma']" /> Figma
                                    </div>
                                </div>
                            </div>
                            <div class="description">
                                <p>Le maquettage en design d'applications est une étape cruciale dans le processus de développement. En créant des maquettes visuelles, nous donnons vie à vos idées et vous permettons de vous projeter et tester l'ergonomie et l'expérience utilisateur avant même de commencer à poser les premières lignes de code.</p>
                                <p>Grâce à cette méthode, nous évitons les modifications qui peuvent s'avérer coûteuses à un stade avancé de votre projet, car les maquettes offrent une vision claire de l'apparence et du fonctionnement de l'application.</p>
                                <p class="tips"><strong>💡 Astuce !</strong> Faire une maquette n'est pas un gage de résultat 100% identique au bout du projet (contraintes techniques ou structuruelles qui empêche parfois un développement à l'identique), mais ça donne les grandes lignes aux acteurs du projet.</p>
                            </div>
                        </div>
                    </div>
                    <div class="step">
                        <div class="content">
                            <div class="overlay" style="background-image:url('https://images.unsplash.com/photo-1515879218367-8466d910aaa4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3869&q=30');"></div>
                            <div class="id">4</div>
                            <h3 class="title">Développement</h3>
                            <div class="technology">
                                <div class="title">Technologies</div>
                                <div class="list">
                                    <div class="item">
                                        <font-awesome-icon :icon="['fab', 'vuejs']" /> VueJS
                                    </div>
                                    <div class="item">
                                        <font-awesome-icon :icon="['fab', 'python']" /> Python
                                    </div>
                                    <div class="item">
                                        <font-awesome-icon :icon="['fab', 'sass']" /> SASS (CSS)
                                    </div>
                                </div>
                            </div>
                            <div class="description">
                                <p>L'étape de développement dans un projet de développement d'application est essentielle pour concrétiser vos idées et transformer vos besoins en une réalité fonctionnelle. C'est à ce stade que nos développeurs travaillent pour transformer les maquettes en une application complète et opérationnelle. Grâce à notre expertise technique, nous prenons en charge la programmation, la création des fonctionnalités et l'intégration des différents composants pour créer une application robuste et performante.</p>
                                <p class="tips"><strong>💡 Astuce !</strong> Nous travaillons avec des technologies qui permettent de faire évoluer dans le futur vos applications, notamment en scalabilité horizontale.</p>
                            </div>
                        </div>
                    </div>
                    <div class="step">
                        <div class="content">
                            <div class="overlay" style="background-image:url('https://images.unsplash.com/photo-1494412651409-8963ce7935a7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3763&q=30');"></div>
                            <div class="id">5</div>
                            <h3 class="title">Livraison</h3>
                            <div class="technology">
                                <div class="title">Technologies</div>
                                <div class="list">
                                    <div class="item">
                                        <font-awesome-icon :icon="['fab', 'github']" /> GitHub
                                    </div>
                                    <div class="item">
                                        <font-awesome-icon :icon="['fab', 'docker']" /> Docker
                                    </div>
                                    <div class="item">
                                        <font-awesome-icon :icon="['fas', 'clouds']" /> Clouder (GCP, AWS, &hellip;)
                                    </div>
                                </div>
                            </div>
                            <div class="description">
                                <p>La phase de livraison dans un projet de développement d'application est une étape excitante où vous voyez enfin votre idée se concrétiser sous la forme d'une application prête à être utilisée. Après des semaines ou des mois de collaboration étroite avec notre équipe de développement, votre application est maintenant prête à être déployée sur les plateformes appropriées, que ce soit sur le web, sur les appareils mobiles ou sur d'autres supports.</p>
                                <p class="tips"><strong>💡 Astuce !</strong> Nous travaillons de manière à vous livrer régulièrement une mise à jour, pour que vous puissiez valider la direction que prends le projet.</p>
                                <p>Nous livrons toujours les sources de votre application sur un répertoire Git, libre à vous de souscrire pour une option en Déploiement Continue (avec des <em>workflows</em> automatisés pour la publication en toute autonomie de votre application et de ses versions à venir)<sup class="footnote"><a href="#footnote-4">4</a></sup>, de travailler avec notre partenaire Liift, expert en transformation Cloud, ou de choisir vous-même un prestataire de votre choix.<sup class="footnote"><a href="#footnote-5">5</a></sup></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a id="hosting"></a>
        <div class="content">
            <div class="block title animation hosting" @mousemove.prevent="moveCard" @mouseout.prevent="outCard">
                <div class="blur">
                    <img :src="`https://images.unsplash.com/photo-1526666923127-b2970f64b422?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=${imgSize}&q=30`" alt="Une antenne parabolique tournée vers le ciel, dans un désert" />
                </div>
                <div class="container">
                    <div class="wrapper">
                        <h2 class="title">Hosting</h2>
                        <div class="subtitle">Levée de rideau pour votre produit innovant ! Hébergement à prix réduit pour un lancement réussi et une expérience utilisateur sans limites.</div>
                    </div>
                </div>
            </div>
            <div class="block cards">
                <div class="grid">

                    <div class="card">
                        <div class="title">
                            <font-awesome-icon :icon="['fad', 'tricycle']" />
                            Starter
                        </div>
                        <div class="description">Nous configurons votre environnement</div>
                        <div class="pricing">
                            <div>
                                <div class="label">Prix mensuel</div>
                                <div class="value">Selon vos besoins</div>
                            </div>
                            <div>
                                <div class="label">Frais d'installation</div>
                                <div class="value">&Agrave; partir de 260,00&nbsp;€</div>
                            </div>
                        </div>
                        <div class="conditions">
                            <div class="subtitle">Conditions</div>
                            <ul>
                                <li>Uniquement pour les développements que nous réalisons</li>
                                <li>Vous êtes propriétaire du projet</li>
                                <li>Limité à GCP, AWS et OVHcloud</li>
                                <li>Support : limité pendant les premiers mois d'exploitation</li>
                            </ul>
                        </div>
                    </div>

                    <div class="card">
                        <div class="soon">Bientôt disponible</div>
                        <div class="title">
                            <font-awesome-icon :icon="['fad', 'shuttle-space']" />
                            WildSys Cloud
                        </div>
                        <div class="description">Nous gérons l'exploitation de votre solution sur notre Cloud</div>
                        <div class="pricing">
                            <div>
                                <div class="label">Prix mensuel</div>
                                <div class="value">Selon vos besoins</div>
                            </div>
                            <div>
                                <div class="label">Frais d'installation</div>
                                <div class="value">Offerts</div>
                            </div>
                        </div>
                        <div class="conditions">
                            <div class="subtitle">Conditions</div>
                            <ul>
                                <li>Support : <em>Best Effort</em> sans limite de temps</li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="block content faq">
            <div class="block text">
                <h2>Questions fréquentes</h2>
                <div class="columns">
                    <div class="column">

                        <div class="item">
                            <div class="icon">
                                <font-awesome-icon :icon="['fad', 'square-question']" />
                            </div>
                            <div class="question">
                                Proposez-vous du référencement naturel&nbsp;?
                            </div>
                            <div class="answer">
                                Non, nous sommes Éditeur de Logiciels ; notre domaine d'expertise est le développement d'applications à forte valeur ajoutée, mais nous laissons à nos clients le soin de promouvoir comme ils le souhaitent leur application.
                            </div>
                        </div>

                        <div class="item">
                            <div class="icon">
                                <font-awesome-icon :icon="['fad', 'square-question']" />
                            </div>
                            <div class="question">
                                Pouvez-vous connecter mon application avec ma banque&nbsp;?
                            </div>
                            <div class="answer">
                                Oui, nous avons une expérience significative avec Stripe mais aussi des APIs bancaires.
                            </div>
                        </div>

                        <div class="item">
                            <div class="icon">
                                <font-awesome-icon :icon="['fad', 'square-question']" />
                            </div>
                            <div class="question">
                                Je souhaite que mon projet reste confidentiel
                            </div>
                            <div class="answer">
                                Nous comprenons le besoin de confidentialité, notamment en phase de conception d'un projet ; si besoin, nous pouvons signer un accord de non-divulgation (ou NDA).
                            </div>
                        </div>

                        <div class="item">
                            <div class="icon">
                                <font-awesome-icon :icon="['fad', 'square-question']" />
                            </div>
                            <div class="question">
                                Pouvez-vous connecter mon application à Slack ou Discord&nbsp;?
                            </div>
                            <div class="answer">
                                Oui, nous sommes habitués à travailler avec les APIs de toute nature&nbsp;: des envois transactionnels en e-mail ou en SMS, en passant par les APIs d'OCR (reconnaissance optique de caractères) jusqu'aux APIs de plateforme collaborative.
                            </div>
                        </div>
                    
                    </div>
                    <div class="column">

                        <div class="item">
                            <div class="icon">
                                <font-awesome-icon :icon="['fad', 'square-question']" />
                            </div>
                            <div class="question">
                                Pouvez-vous reprendre un développement d'un autre éditeur ?
                            </div>
                            <div class="answer">
                                Nous pouvons bien entendu reprendre un développement qui a déjà été mené pour le faire évoluer. Nous prendrons le temps d'auditer le code auparavant pour nous assurer que nous pourrons vous fournir le niveau de qualité que nous souhaitons fournir à tous nos clients.
                            </div>
                        </div>

                        <div class="item">
                            <div class="icon">
                                <font-awesome-icon :icon="['fad', 'square-question']" />
                            </div>
                            <div class="question">
                                Êtes-vous conforme avec la RGPD&nbsp;?
                            </div>
                            <div class="answer">
                                Tout au long du projet, nous sommes amené à vous conseiller sur l'utilisation ou le stockage des données personnelles. Si nous sommes amenés à travailler avec des données de votre entreprise, nous appliquerons le règlement : les données seront stockés et traités dans des datacenters européens, éventuellement sur nos postes (France), et toujours supprimée dès la fin de la mission.<br />
                                Nous savons que l'image de nos clients dépends non seulement des produits que nous développons mais également de la qualité du traitement des données de vos utilisateurs.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="block content footnotes">
            <ol>
                <li>
                    <a id="footnote-1"></a> Service inclus pour une période déterminée, indiquée au moment du devis, pouvant faire l'objet d'une prorogation par un Contrat de Support complémentaire.
                </li>
                <li>
                    <a id="footnote-2"></a> Donné à titre indicatif, ne pouvant faire l'objet de pénalités.
                </li>
                <li>
                    <a id="footnote-3"></a> Mis à disposition à titre gratuit le temps du développement sans garantie de performance ni de disponibilité.
                </li>
                <li>
                    <a id="footnote-4"></a> Vous seuls prenez en charge les coûts liés à l'exploitation du logiciel.
                </li>
                <li>
                    <a id="footnote-5"></a> Des frais complémentaires peuvent s'appliquer pour les contraintes logicielles imposées par votre prestataire.
                </li>
            </ol>
        </div>
    </div>
    <DefaultFooter />
</template>

<script setup lang="ts">
import HomeHeader from '@/components/home/HomeHeader.vue'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { isMobile } from 'mobile-device-detect'

let currentStep = null
let autoFocus = null
let lockScroll = null
let wheelDirection = null
let wheelInit = window.scrollY
let wheelDelta = 0
const imgSize = ref(isMobile ? 1512 : 600)

const moveCard = function($event: MouseEvent){
    let obj = ($event.target as HTMLElement)
    while (!obj.className.includes('animation')) obj = obj.offsetParent
    const blurObj = (obj.querySelector('.blur') as HTMLElement)
    if (blurObj) {
        const width = blurObj.offsetWidth
        const height = blurObj.offsetHeight
        const mousePx = ($event.pageX - blurObj.offsetLeft - width) / width
        const mousePy = ($event.pageY - (blurObj.offsetTop + blurObj.offsetParent.offsetTop) - height/2) / height
        const tX = mousePx * -20
        const tY = mousePy * -20
        blurObj.style.transform = `translateX(${tX}px) translateY(${tY}px) scale(1.02)`
    }
    return false
}

const outCard = function($event: MouseEvent){
    setTimeout(() => {
        let obj = ($event.target as HTMLElement)
        while (!obj.className.includes('animation')) obj = obj.offsetParent
        const blurObj = (obj.querySelector('.blur') as HTMLElement)
        if (blurObj) {
            blurObj.style.transform = 'translateX(0deg) translateY(0deg) scale(1.02)'
        }
    }, 500)
    return false
}

const manageScroll = function(){

    document.querySelectorAll('.blur').forEach((blurObj) => {
        const bounds = blurObj.getBoundingClientRect()
        const scrollTop = bounds.top - 60
        // blurObj.style.height = blurObj.offsetParent.querySelector('.content').offsetHeight
        blurObj.scrollTo({
            top: scrollTop,
            left: 0
        })
    })

    if (window.innerWidth <= 600) return false

    currentStep = null
    clearTimeout(autoFocus)
    autoFocus = setTimeout(() => {
        if (!lockScroll) {
            wheelDirection = window.scrollY > wheelInit ? 'upToDown' : 'downToUp'
            wheelDelta = window.scrollY - wheelInit
            wheelInit = window.scrollY
            let focusPosition = null
            const debug = document.querySelector('#debug')
            document.querySelectorAll('.parallax .step').forEach((objStep) => {
                const wHeight = window.innerHeight
                const margin = wHeight / 2
                const anchor = wHeight / 5
                const anchor2 = wHeight / 3
                const oTop = parseInt(objStep.getAttribute('data-originalPosition'), 10)
                const isInLine =  (oTop - margin) < window.scrollY && window.scrollY < (oTop + margin)
                debug.innerHTML = `${debug?.innerHTML}<br>${oTop}px / ${wheelDirection} : ${wheelDelta} / ${oTop - margin} < ${window.scrollY} < ${oTop + margin} ? ${isInLine} / ${lockScroll}`
                debug.innerHTML = `${debug?.innerHTML}<br>${wheelDelta} <> ${(wHeight / anchor)}`
                debug?.scrollTo({top: 100000000000})
                if (wheelDirection == 'upToDown' && isInLine && Math.abs(wheelDelta) > (wHeight / anchor)) {
                    focusPosition = oTop
                }
                else if (wheelDirection == 'downToUp' && isInLine && Math.abs(wheelDelta) > (wHeight / anchor2)) {
                    focusPosition = oTop
                }
                else if (window.scrollY > oTop && window.scrollY < (oTop + wHeight)) {
                    focusPosition = oTop
                }
            })
            if (focusPosition !== null) {
                debug.innerHTML = `${debug.innerHTML}<br>FOCUS! (${focusPosition})`
                debug?.scrollTo({top: 100000000000})
                lockScroll = setTimeout(() => {
                    lockScroll = null
                }, 500)
                window.scrollTo({top: focusPosition, left: 0, behavior: 'smooth'})
            }
        }
    }, 100)

    document.querySelectorAll('.parallax .step').forEach((objStep, idx) => {
        const bounds = objStep.getBoundingClientRect()
        let prevStep = objStep.previousSibling
        if (!objStep.getAttribute('data-originalPosition')) objStep.setAttribute('data-originalPosition', objStep.offsetTop)
        if (prevStep && !prevStep.className?.includes('step')) prevStep = null
        let nextStep = objStep.nextSibling
        if (nextStep && !nextStep.className?.includes('step')) nextStep = null
        const wHeight = window.innerHeight
        let opacity = ((wHeight / 1.5) - bounds.top) / (wHeight / 1.5)
        if (nextStep) {
            const nextBounds = nextStep.getBoundingClientRect()
            if (nextBounds.top >= 0) opacity -= ((wHeight - nextBounds.top) / wHeight)
        }
        else if (bounds.top < -(wHeight / 10)) {
            clearTimeout(autoFocus)
        }
        opacity = Math.round(opacity * 100) / 100
        if (opacity > 1) opacity = 1
        if (bounds.top < 0 && nextStep) opacity = 0
        if (bounds.top <= 100) objStep.classList.add('active')
        else objStep.classList.remove('active')
        objStep.style.opacity = opacity
    })

}

const attachEvents = function(){
    window.addEventListener('scroll', manageScroll)
}

const dettachEvents = function(){
    window.removeEventListener('scroll', manageScroll)
}

onMounted(attachEvents)
onBeforeUnmount(dettachEvents)
</script>

<style lang="scss" scoped>
$mobile_size: 600px;

@keyframes wheel_animation {
    0% { top: 5px; opacity: 1; }
    100% { top: 15px; opacity: 0; }
}

.view.home
{
    min-height: 100vh;
    min-width: 100vw;
    .intro
    {
        height: 100vh;
        width: 100%;
        background-color: #4D5036;
        background-image: url('https://images.unsplash.com/photo-1535483528327-bf88ef6e1c9e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3648&q=30');
        background-attachment: fixed;
        background-position: center center;
        background-size: 100% auto;
        display: grid;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        text-align: center;
        @supports (-webkit-overflow-scrolling: touch) {
            background-attachment: scroll;
        }
        .text
        {
            max-width: 600px;
            font-size: 1.2em;
            .title
            {
                font-size: 2em;
                font-weight: bold;
            }
        }
        @media (max-width: $mobile_size) {
            background-size: auto 100%;
            .text
            {
                display: grid;
                align-items: center;
                justify-content: center;
                height: 100vh;
                padding: 0px 60px;
                background-color: rgba(#9498a4, 50%);
                text-shadow: #000000 0px 1px 1px;
            }
        }
    }
    .content
    {
        .block
        {
            &.title
            {
                margin: 120px auto 60px auto;
                max-width: 600px;
                font-size: 1.2em;
                text-align: center;
                color: #636875;
                @media (max-width: $mobile_size) {
                    max-width: 80vw;
                    width: 80vw;
                }
                .title
                {
                    font-weight: bold;
                    font-size: 1.3em;
                }
                &.fixed
                {
                    position: sticky;
                    top: 100px;
                    z-index: 2;
                }
            }
            &.text
            {
                padding: 30px 80px;
                max-width: 1200px;
                margin: auto;
                @media (max-width: $mobile_size) {
                    padding: 30px 30px;
                }
                &:last-of-type
                {
                    padding-bottom: 120px;
                }
                .columns
                {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    column-gap: 100px;
                    .column
                    {
                        width: calc(50% - 60px);
                        padding: 30px 0px;
                        .title
                        {
                            font-weight: bold;
                        }
                        @media (max-width: $mobile_size) {
                            width: 100%;
                            padding: 20px 0px;
                        }
                    }
                }
                .scroll
                {
                    background-color: lighten(#334155, 60%);
                    color: #334155;
                    display: grid;
                    grid-template-columns: 1fr auto;
                    justify-content: center;
                    padding: 15px 30px;
                    border-radius: 4px;
                    align-items: center;
                    column-gap: 20px;
                    .icon
                    {
                        .mouse
                        {
                            border: 2px solid #334155;
                            width: 16px;
                            height: 26px;
                            border-radius: 8px;
                            position: relative;
                            .wheel
                            {
                                width: 4px;
                                height: 4px;
                                background-color: #334155;
                                position: absolute;
                                left: 50%;
                                margin-left: -2px;
                                top: 5px;
                                border-radius: 100px;
                                animation-name: wheel_animation;
                                animation-duration: 2s;
                                animation-iteration-count: infinite;
                                animation-timing-function: ease-in;
                                animation-direction: reverse;
                            }
                        }
                    }
                }
                .tips
                {
                    color: #5e656f;
                    background-color: #f7f7f7;
                    padding: 15px 30px;
                    border-radius: 8px;
                }
            }
            &.animation
            {
                max-width: 800px;
                color: #ffffff;
                text-shadow: #000000 0px 0px 15px,
                    #000000 0px 1px 1px;
                position: relative;
                @media (max-width: $mobile_size) {
                    max-width: 80vw;
                }
                .container,
                .blur
                {
                    position: relative;
                    background-repeat: no-repeat;
                    background-size: 100% auto;
                    background-position: center;
                    background-attachment: fixed;
                    border-radius: 10px;
                    @media (max-width: $mobile_size) {
                        background-size: auto 100%;
                    }
                    @supports (-webkit-overflow-scrolling: touch) {
                        background-attachment: scroll;
                    }
                    &.blur
                    {
                        position: absolute;
                        filter: blur(1em);
                        transform-origin: 50% 50%;
                        transform: scale(1.02);
                        overflow: hidden;
                        width: 100%;
                        height: 100%;
                        left: 0px;
                        top: 0px;
                        padding: 0px;
                        @supports (-webkit-overflow-scrolling: touch) {
                            display: none;
                        }
                        img
                        {
                            position: relative;
                            width: 100%;
                        }
                    }
                    .wrapper
                    {
                        background-color: rgba(#000000, 30%);
                        border-radius: 10px;
                        padding: 50px 70px;
                        @media (max-width: $mobile_size) {
                            padding: 40px 30px;
                        }
                    }
                }
                &.ctoaas>.container
                {
                    background-image: url('https://images.unsplash.com/photo-1572177812156-58036aae439c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1512&q=30');
                }
                &.hosting>.container
                {
                    background-image: url('https://images.unsplash.com/photo-1526666923127-b2970f64b422?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1512&q=30');
                }
                &.develop>.container
                {
                    background-image: url('https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1512&q=30');
                }
            }
            &.cards
            {
                max-width: 1200px;
                padding: 60px 30px;
                margin: 0px auto;
                display: grid;
                justify-content: center;
                .grid
                {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    column-gap: 60px;
                    align-items: flex-start;
                    @media (max-width: $mobile_size) {
                        grid-template-columns: repeat(1, 1fr);
                        row-gap: 20px;
                    }
                    .card
                    {
                        background-color: #ffffff;
                        border-radius: 8px;
                        box-shadow: #efefef 0px 0px 12px,
                            #dbdbdb 0px 1px 0px;
                        width: 320px;
                        margin: 0px auto;
                        padding: 25px 0px;
                        position: relative;
                        overflow: hidden;
                        .soon
                        {
                            position: absolute;
                            transform: rotate(35deg);
                            transform-origin: 50% 50%;
                            background-color: #009C9B;
                            box-shadow: #009c9c7a 0px 0px 15px;
                            z-index: 2;
                            color: #ffffff;
                            padding: 4px 0px;
                            font-weight: 600;
                            font-size: 0.85em;
                            text-align: center;
                            width: 500px;
                            top: 40px;
                            left: 0px;
                        }
                        .title
                        {
                            text-align: center;
                            padding: 40px 15px 35px 15px;
                            font-weight: 500;
                            font-size: 1.15em;
                            color: #636875;
                            [class*="fa"]
                            {
                                margin-right: 0.5em;
                                font-size: 1.25em;
                                vertical-align: middle;
                                position: relative;
                                top: -0.1em;
                                color: #02B97D;
                            }
                        }
                        .subtitle
                        {
                            font-weight: 500;
                            font-size: 0.9em;
                        }
                        .description
                        {
                            padding: 7px 30px;
                            text-align: center;
                            color: #636875;
                        }
                        .pricing
                        {
                            margin: 30px 30px;
                            &>div
                            {
                                text-align: center;
                                padding: 8px 0px;
                                .label
                                {
                                    color: #9498a4;
                                    font-size: 0.9em;
                                    text-align: center;
                                }
                                .value
                                {
                                    font-size: 1em;
                                }
                            }
                        }
                        .conditions
                        {
                            color: #636875;
                            padding: 7px 30px;
                            ul
                            {
                                margin: 0px 0px 0px 1em;
                                padding: 0px;
                                li
                                {
                                    margin: 5px 0px;
                                    padding: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.faq
        {
            background-color: #ffffff;
            margin: 50px 0px;
            padding: 60px 0px;
            .item
            {
                position: relative;
                padding: 0px 0px 0px 2.5em;
                margin: 30px 0px;
                .icon
                {
                    color: #9498a4;
                    font-size: 1.75em;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                }
                .question
                {
                    font-weight: 500;
                    padding: 7px 0px;
                }
                .answer
                {
                    text-align: justify;
                }
            }
            @media (max-width: $mobile_size) {
                .text>.columns>.column
                {
                    margin: 0px;
                    padding: 0px;
                }   
                .text>.columns>.column>.item
                {
                    margin-top: 0px;
                }
            }
        }
        &.footnotes
        {
            padding: 30px 80px;
            max-width: 1200px;
            margin: auto;
            color: rgba(#334155, 70%);
            font-size: 0.85em;
            @media (max-width: $mobile_size) {
                padding: 30px 40px 30px 20px;
                ol
                {
                    margin: 0px;
                }
            }
        }
        sup.footnote
        {
            position: relative;
            font-size: 0.7em;
            top: -2px;
            vertical-align: middle;
            a
            {
                color: #334155;
                font-size: 0.85em;
                position: absolute;
            }
        }
    }
    .parallax
    {
        min-height: 100vh;
        .step
        {
            position: sticky;
            top: 0vh;
            z-index: 1;
            padding: 60px 0px;
            height: 100vh;
            display: grid;
            justify-content: center;
            align-items: center;
            @media (max-width: $mobile_size) {
                height: auto;
                position: relative;
                display: block;
            }
            .content
            {
                background-color: #ffffff;
                padding: 16px 30px 16px calc(30% + 30px);
                border-radius: 8px;
                box-shadow: #dbdbdb 0px 0px 4px;
                position: relative;
                @media (max-width: $mobile_size) {
                    padding: 16px 30px;
                    width: 100%;
                }
                .overlay
                {
                    @media (max-width: $mobile_size) {
                        display: none;
                    }
                    margin: -16px -30px -16px calc(-30% + -30px);
                    position: absolute;
                    overflow: hidden;
                    height: 100%;
                    width: 100%;
                    border-radius: inherit;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all 0.7s 0s;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 140vh auto;
                }
            }
            .id
            {
                text-align: center;
                font-size: 2em;
                color: lighten(#334155, 60%);
                font-weight: 300;
            }
            .title
            {
                text-align: center;
                font-size: 1.5em;
                font-weight: 500;
                color: lighten(#334155, 15%);
            }
            .technology
            {
                text-align: center;
                color: lighten(#334155, 40%);
                margin: 40px 0px;
                .title
                {
                    font-size: 1.2em;
                    color: inherit;
                }
                .list
                {
                    display: grid;
                    padding: 8px 0px;
                    justify-content: center;
                    grid-auto-flow: column;
                    column-gap: 30px;
                    @media (max-width: $mobile_size) {
                        grid-auto-flow: row;
                        row-gap: 5px;
                    }
                }
            }
            &.active
            {
                .overlay
                {
                    width: 30%;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
            }
        }
    }
}
#debug
{
    position: fixed;
    background-color: #ffffff;
    padding: 16px 30px;
    left: 30px;
    top: 30px;
    max-height: 40vh;
    width: 40vw;
    box-shadow: #000000 0px 0px 4px;
    overflow: auto;
    z-index: 100000;
    display: none;
}
</style>