<template>
    <transition name="slide">
        <div class="banner-container" :class="{'show': showBanner}">
            <p>Pouvons-nous placer des 🍪 sur votre navigateur ?
                <router-link to="/cookies">Voir notre politique sur les Cookies</router-link>
            </p>
            <p>
                <button class="ui button" @click="okBannerClicked">Accepter</button>
                &nbsp;
                <button class="ui button" @click="nopeBannerClicked">Refuser</button>
            </p>
        </div>
    </transition>
</template>

<script setup lang="ts">
import { inject } from 'vue';
import useCookies from '@/helpers/useCookies'
import { useStore } from 'vuex'

const gtag = inject<any>('gtag')
const { showBanner, okClicked } = useCookies(gtag)
const store = useStore()

const okBannerClicked = function(){
    return okClicked()
}

const nopeBannerClicked = function(){
    store.commit('nocookies')
}
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.5s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
}

.banner-container
{
    position: fixed;
    z-index: 1000;
    bottom: 15px;
    left: 15px;
    background-color: #ffffffde;
    backdrop-filter: blur(5px);
    border-radius: 6px;
    box-shadow: rgba(#000000, 15%) 0px 2px 5px;
    padding: 12px 20px;
    display: none;
    &.show
    {
        display: block;
    }
}
</style>
