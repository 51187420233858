<template>
    <div class="cmp header" :class="{'reduced': headerReduced}" ref="header">
        <div class="wrapper">
            <div class="links">
                <router-link to="/" class="home" @click.prevent="smoothScroll('#home')">
                    <div class="logo">
                        <LogoSVG :fill="headerReduced ? '#788d9c' : '#ffffff'" />
                    </div>
                    <div class="text">WildSys</div>
                </router-link>
                <a href="#ctoaas" @click.prevent="smoothScroll('#ctoaas')" class="link">
                    CTO as a Service
                </a>
                <a href="#develop" @click.prevent="smoothScroll('#develop')" class="link">
                    Développement
                </a>
                <a href="#hosting" @click.prevent="smoothScroll('#hosting')" class="link">
                    Hébergement
                </a>
            </div>
            <div class="actions">
                <router-link to="/contact" class="button">
                    Contact
                </router-link>
            </div>
            <div class="pwa toggle" @click="mobileMenu = !mobileMenu">
                <font-awesome-icon :icon="['fas', 'bars']" />
            </div>
            <div class="pwa menu" :class="{'active': mobileMenu}">
                <div class="close" @click="mobileMenu = false">
                    <font-awesome-icon :icon="['fal', 'circle-xmark']" />
                </div>
                <div class="logo">
                    <LogoSVG fill="#788d9c" />
                </div>
                <a href="#ctoaas" @click.prevent="smoothScroll('#ctoaas')" class="link">
                    CTO as a Service
                </a>
                <a href="#develop" @click.prevent="smoothScroll('#develop')" class="link">
                    Développement
                </a>
                <a href="#hosting" @click.prevent="smoothScroll('#hosting')" class="link">
                    Hébergement
                </a>
                <hr />
                <router-link to="/contact" class="link">
                    Contact
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import LogoSVG from '@/components/design/LogoSVG.vue'
import { ref, onBeforeUnmount, onMounted } from 'vue'

const header = ref<HTMLElement|null>(null)
const headerReduced = ref(false)
const mobileMenu = ref(false)

const manageScroll = function(evt: MouseEvent){
    const scrollY = window.scrollY
    headerReduced.value = (scrollY > (header.value as HTMLElement)?.offsetHeight)
}

const attachEvents = function(){
    window.addEventListener('scroll', manageScroll)
}

const dettachEvents = function(){
    window.removeEventListener('scroll', manageScroll)
}

const smoothScroll = function(anchor){
    window.scrollTo({
        top: document.body.querySelector(anchor).offsetTop,
        behavior: 'smooth'
    })
}

onMounted(() => attachEvents())
onBeforeUnmount(() => dettachEvents())
</script>

<style lang="scss" scoped>
$reduced_size: 22px;
$mobile_size: 600px;

.cmp.header
{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    transition: all 0.4s 0s;
    z-index: 10;
    .wrapper
    {
        display: grid;
        grid-template-columns: repeat(2, auto);
        justify-content: space-between;
        max-width: 1200px;
        margin: auto;
        transition: inherit;
        .links
        {
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            justify-content: flex-start;
            column-gap: 30px;
            transition: inherit;
            .home
            {
                padding: 8px 16px;
                color: #ffffff;
                margin-right: 20px;
                height: 80px;
                width: 120px;
                text-align: center;
                transition: inherit;
                .logo
                {
                    width: 42px;
                    height: 42px;
                    margin: auto;
                    transition: inherit;
                    svg
                    {
                        transition: inherit;
                        width: 42px;
                        height: 42px;
                    }
                }
                .text
                {
                    transition-property: transform;
                    transition-duration: inherit;
                    transition-delay: inherit;
                    font-weight: 500;
                }
            }
            .link
            {
                font-size: 0.9em;
                color: #ffffff;
                @media (max-width: $mobile_size) {
                    &
                    {
                        display: none;
                    }
                }
            }
        }
        .actions
        {
            display: grid;
            align-items: center;
            transition: inherit;
            .button
            {
                transition: inherit;
                background-color: #ffffff;
                color: #5e7382;
                border-radius: 100px;
                padding: 8px 16px;
                font-weight: 500;
                font-size: 0.9em;
            }
            @media (max-width: 1024px) {
                &
                {
                    display: none;
                }
            }
        }
        .pwa.toggle
        {
            width: 64px;
            font-size: 22px;
            color: #ffffff;
            display: none;
            justify-content: center;
            align-items: center;
            transition: all 0.4s 0s;
            @media (max-width: $mobile_size) {
                &
                {
                    display: grid;
                }
            }
        }
    }
    &.reduced
    {
        background-color: rgba(#ffffff, 93%);
        backdrop-filter: blur(5px);
        .wrapper
        {
            .links
            {
                .home
                {
                    color: #5e7382;
                    height: ($reduced_size * 2);
                    padding-top: ($reduced_size / 2);
                    .logo
                    {
                        width: $reduced_size;
                        height: $reduced_size;
                        transform: translateX(-$reduced_size) translateY(-1px);
                        svg
                        {
                            width: $reduced_size;
                            height: $reduced_size;
                        }
                    }
                    .text
                    {
                        transform: translateX($reduced_size + 6px) translateY(-$reduced_size);
                    }
                }
                .link
                {
                    color: #788d9c;
                }
            }
            .actions
            {
                .button
                {
                    padding: 4px 16px;
                }
            }
            .pwa.toggle
            {
                color: #788d9c;
                font-size: 1em;
            }
        }
    }
    .pwa.menu
    {
        position: fixed;
        left: 0px;
        margin-top: -100%;
        background-color: #ffffff;
        width: 100%;
        padding: 8px 0px;
        opacity: 0;
        transition: all 0.4s 0s;
        box-shadow: rgba(#5e7382, 12%) 0px 0px 12px;
        hr
        {
            margin: 12px 40px 8px 40px;
            border: none;
            border-bottom: 1px solid #e2eef5;
        }
        .close
        {
            position: absolute;
            height: 24px;
            height: 24px;
            display: grid;
            align-items: center;
            justify-content: center;
            right: 10px;
            top: 10px;
            color: rgba(#5e7382, 50%);
            font-size: 22px;
        }
        .logo
        {
            text-align: center;
            svg
            {
                height: 42px;
            }
        }
        .link
        {
            display: block;
            color: #5e7382;
            padding: 8px 16px;
        }
        &.active
        {
            margin-top: 0px;
            opacity: 1;
        }
    }
}
</style>